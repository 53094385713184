import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../context/userContext";

const PrivateRoute = ({ children }) => {
    const {userLoggedIn} = useUserContext()
  if (userLoggedIn) {
    return children ? children : <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;