import React, { useEffect, useState } from "react";
import "./leaderboard.css";
import { gameDataInstance } from "../../config/axiosConfig";
import Layout from "../../components/layout";
import Loader from "../../components/loader/loader";
import ReactPaginate from "react-paginate";

const LeaderBoard = () => {
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const limit = 10; // Set the number of items per page

  const handleBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const getLeaderboard = async (page) => {
      setLoading(true); 
      try {
        const response = await gameDataInstance().get(
          `/leaderboard?page=${page}&limit=${limit}`
        );
        setLeaderBoardData(response.data.leaderboard);
        setCurrentPage(page); 
        setTotalItems(response.data.totalItems);
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      } finally {
        setLoading(false); 
      }
    };

    getLeaderboard(currentPage); 
  }, [currentPage]); 

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1; // Adjust for 0-indexed selected
    setCurrentPage(selectedPage); // Update the current page state
  };

  console.log('Leaderboard',leaderBoardData )

  return (
    <Layout>
      <div className="side-info">
        <button className="back-button" onClick={handleBack}>
          <img src="/Images/back-button.svg" alt="back" /> Back
        </button>
        <h2 className="head-name">
          <span>Leader</span>board
        </h2>
      </div>

      <div className="sub-cat-wrapper leader-board-container">
        <div className="leader-board-wrapper">
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          ) : leaderBoardData && leaderBoardData.length > 0 ? (
            leaderBoardData.map((data, index) => (
              <div
                className={`leader-card ${currentPage === 1 ? "winners" : ""}`}
                key={index}
              >
                <div className="d-flex align-items-center gap-3">
                  <div className="leader-rank">
                    {(currentPage - 1) * limit + index + 1}
                  </div>
                  <div className="leader-img">
                    <img
                      src={data.profile || "https://via.placeholder.com/50"}
                      alt="profile-img"
                      className="leader-profile-img"
                    />
                  </div>
                  <h3>
                    {`${data?.firstName || "---"} ${data?.lastName || ""}`}
                  </h3>
                </div>

                <div className="score-section">
                  <img src="/Images/trophy.png" alt="trophy-icon" />
                  <p>
                  {data?.averageScore !== null && data?.averageScore !== undefined ? data.averageScore.toFixed(2) : "---"}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p>No leaderboard data available.</p>
          )}
        </div>
      </div>
      <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(totalItems / limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        disabledClassName={"disabled"}
      />
    </Layout>
  );
};

export default LeaderBoard;
