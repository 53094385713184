import React from "react";

const CertificatePending = ({ gamesResponse, setShowGamesPending }) => {
  const requiredGames = ["Alphabets", "Numbers", "Colours"];

  const gameStatusCheck = (title) => {
    const status = gamesResponse.includes(title) ? "Completed" : "Pending";
    return status;
  };

  return (
    <div className="certificate-container">
      <div className="pending-body">
        <div className="text-center">
          <p className="pending-title">Games Status</p>
          <p className="pending-subtitle">
            You need to finish all the games to get your certificate{" "}
          </p>
        </div>
        {requiredGames.map((game) => (
          <GameStatusCard title={game} status={gameStatusCheck(game)} />
        ))}
      </div>
      <div className="text-center mt-4">
        <button
          className="ok-button"
          onClick={() => setShowGamesPending(false)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default CertificatePending;

export const GameStatusCard = ({ title, status }) => {
  return (
    <div className="game-status-card">
      <p>{title}</p>

      <img
        src={status === "Completed" ? "/Images/Tick.png" : "/Images/cross.png"}
        alt={status}
      />
    </div>
  );
};
