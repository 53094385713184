import * as yup from 'yup'
// const today = new Date();
// const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
export const RegisterSchema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName:yup.string().required('Last name is required'),
    userName:yup.string().required('Username is required').test('is-lowercase', 'Username must be in lowercase',
       value => {
      return value === value?.toLowerCase();
    }),
    email: yup.string()
    .email('Invalid email format')  // Default email validation
    .required('Email is required')
    .test('is-case-sensitive', 'Email must be in lowercase', value => {
      return value === value?.toLowerCase();
    }),
    country: yup
      .string()
      .required('Please select your country'),
    dateOfBirth: yup
      .date()
      .required('Date of birth is required'),
    password: yup.string().required('Password is required'),
  })
  export const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email('Please enter valid email')
      .required('Email is required').test('is-case-sensitive', 'Email must be in lowercase', value => {
        return value === value?.toLowerCase();
      }),
    password: yup.string().required('Password is required'),
  })