import React from "react";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <div className="landing-wrapper">
      <div className="landing-container">
        <Header />
        {children}
      </div>
    </div>
  );
};

export default Layout;
