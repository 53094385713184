import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authInstance } from "../config/axiosConfig";
import { toast } from "react-toastify";
import Loader from "../components/loader/loader";

function ResetPassword({ setShowForgotModal }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sendResetLink = async (email) => {
    setLoading(true);
    try {
      const response = await authInstance().post("/request-password-reset", {
        email,
      });
      const {
        status,
        data: { message },
      } = response;
      if (status === 200) {
        toast.success(message, { toastId: "reset-link-sent" });
        navigate("/");
        setLoading(false);
        setShowForgotModal(false);
      } else {
        toast.error(message, { toastId: "errorLinkk" });
        setLoading(false);
      }
    } catch (error) {
      console.log("Error", error.response.data.message);
      toast.error(error.response.data.message, { toastId: "errorLink" });
      setLoading(false)
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const verifyEmail = async (values) => {
    await sendResetLink(values.email);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div className="login-cover">
          <img src="/Images/cat.png" className="cat-img" alt="Cover" />
        </div>

        <div className="login-form">
          <div className="login-popup-content">
            <h2>Verify Email</h2>

            <Form onSubmit={handleSubmit(verifyEmail)}>
              <Form.Group className="full-w">
                <Form.Control
                  className={`${errors?.email ? `error-border` : ``}`}
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  {...register("email", { required: "Email is required" })}
                />
                {errors?.email && (
                  <span className="error-msg">{errors.email.message}</span>
                )}
              </Form.Group>

              <div className="full-w">
                <Button className="auth-btn" type="submit">
                  {loading ? <Loader /> : "Verify Email"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
