import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import Layout from "../components/layout";
import { authInstance } from "../config/axiosConfig";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import { EyeIcon, EyesShut } from "./login";

function ResetPasswordd() {
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const updatePassword = async (values) => {
    const { password, confirmPassword } = values;
    setPasswordError(false);
    if (password !== confirmPassword) {
      setPasswordError(true);
      return;
    }

    setLoading(true);
    // console.log("New password submitted:", password);
    try {
      const token = queryParams.get("token");
      const response = await authInstance().post("/reset-password", {
        newPassword: password,
        token,
      });
      const {
        status,
        data: { message },
      } = response;
      if (status === 200) {
        toast.success(message, { toastId: "reset-password" });
        navigate("/");
        setLoading(false);
      } else {
        toast.error(message, { toastId: "errorLinkk" });
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.message, { toastId: "errorLink" });
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className="auth-wrapper reset-password-wrapper">
        <div className="auth-container">
          <div className="login-cover">
            <img src="/Images/cat.png" className="cat-img" alt="Cover" />
          </div>

          <div className="login-form">
            <div className="login-popup-content">
              <h2>Update Password</h2>
              <Form onSubmit={handleSubmit(updatePassword)}>
                <>
                  {/* New Password */}
                  <Form.Group className="full-w">
                    <div className="pass-show password-input ">
                      <Form.Control
                        className={`${errors?.password ? `error-border` : ``}`}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="New Password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                          },
                        })}
                      />
                      <span
                        className="eye-icon"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <EyesShut /> : <EyeIcon />}
                      </span>
                    </div>
                    {errors?.password && (
                      <span className="error-msg">
                        {errors.password.message}
                      </span>
                    )}
                  </Form.Group>

                  {/* Confirm Password */}
                  <Form.Group className="full-w">
                    <div className="pass-show password-input ">
                      <Form.Control
                        className={`${
                          errors?.confirmPassword ? `error-border` : ``
                        }`}
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        {...register("confirmPassword", {
                          required: "Confirm password is required",
                          minLength: {
                            value: 6,
                            message: "Password must be at least 6 characters",
                          },
                        })}
                      />
                      <span
                        className="eye-icon"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? <EyesShut /> : <EyeIcon />}
                      </span>
                    </div>
                    {errors?.confirmPassword && (
                      <span className="error-msg">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </Form.Group>
                </>

                {/* Show error if passwords don't match */}
                {passwordError && (
                  <div className="match-error d-flex align-items-center justify-content-center">
                    <p className="mb-0 text-danger">
                      The passwords did not match!
                    </p>
                  </div>
                )}

                <div className="full-w">
                  <Button className="auth-btn" type="submit">
                    {loading ? <Loader /> : "Update Password"}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ResetPasswordd;
