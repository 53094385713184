import React from "react";

const Certificate = ({ studentName, completionDate }) => {
  return (
    <div className="certificate-container">
      <div className="certificate">
        <div className="water-mark-overlay"></div>
        <div className="certificate-header">
          <img src="/Images/logo.png" className="logo" alt="Logo" />
        </div>
        <div className="certificate-body">
          <p className="certificate-title">
            <strong>The Sabian Mandaean Association of Australia</strong>
          </p>
          <h1>Certificate of Completion</h1>
          <p className="student-name">{studentName}</p>
          <div className="certificate-content">
            <div className="about-certificate">
              <p>has completed all games. online on Date <br /> {completionDate}</p>
            </div>
            <p className="topic-title">The Topic includes the following:</p>
            <div className="text-center">
              <p className="topic-description ">
                Learning Alphabets, Learning Numbers and Learning Colours.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
