import React from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../components/layout";

const SubCategory = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status");
  const game = queryParams.get("game");
  const handleBack = () => {
    window.history.back();
  };
  return (
    <Layout>
      <div>
        <div className="side-info">
          <button className="back-button" onClick={handleBack}>
            <img src="/Images/back-button.svg" alt="back" /> Back
          </button>
          <h2 className="head-name">
            {game === "Alphabets" ? (
              <>
                <span>A</span>lphabets
              </>
            ) : game === "Numbers" ? (
              <>
                <span>N</span>umbers
              </>
            ) : (
              <>
                <span>C</span>olours
              </>
            )}
          </h2>
        </div>

        <div className="sub-cat-wrapper">
          <div className="card-cover-1">
            <div
              className={`cat-game-status ${
                status === "New" ? "new-game" : "completed-game"
              } `}
            >
              {status}
            </div>
            {game === "Alphabets" ? (
              <Link to="/learn-alphabet">
                <img src="/Images/learn-alphabet.jpg" alt="" />
                <span>Enter</span>
              </Link>
            ) : game === "Numbers" ? (
              <Link to="/learn-number">
                <img src="/Images/learn-number.jpg" alt="" />
                <span>Enter</span>
              </Link>
            ) : (
              <Link to="/learn-color">
                <img src="/Images/learn-color.jpg" alt="" />
                <span>Enter</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SubCategory;
