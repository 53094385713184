import { createContext, useContext, useEffect, useState } from "react";
import { authInstance } from "../config/axiosConfig";

const MyContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUserLoggedIn(true);
    }
  }, [userLoggedIn]);

  return (
    <MyContext.Provider
      value={{
        userLoggedIn,
        setUserLoggedIn,

        showLogin,
        setShowLogin,
        showRegister,
        setShowRegister,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(MyContext);
};
