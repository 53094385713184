import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../context/userContext";

const HomeSlider = ({ games }) => {
  const {
    userLoggedIn,

    setShowLogin,
  } = useUserContext();

  const gameStatusCheck = (title) => {
    const status = games.includes(title) ? "Completed" : "New";
    return status;
  };

  const GameTiles = [
    {
      title: "Alphabets",

      img: "/Images/Alphabet.png",
      status: "New",
    },
    {
      title: "Numbers",

      img: "/Images/Numbers.png",
      status: "New",
    },
    {
      title: "Colours",

      img: "/Images/Color.png",
      status: "New",
    },
  ];

  useEffect(() => {
    GameTiles.map((game) => {
      const status = gameStatusCheck(game.title);
      if (status === "Completed") {
        game.status = "Completed";
      }
    });
  }, [userLoggedIn]);

  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigate = (tile) => {
    if (userLoggedIn) {
      let data = {};
      if (gameStatusCheck(tile.title) === "New") {
        data = { game: tile.title, status: "New" };
      } else {
        data = { game: tile.title, status: "Completed" };
      }
      const queryString = new URLSearchParams(data).toString();
      navigate(`/category?${queryString}`);
    } else {
      setShowLogin(true);
    }
  };

  return (
    <div className="slider-container col-6  home-slider">
      <Slider {...settings}>
        {GameTiles.map((tile, index) => (
          <div
            key={index}
            className="cat-card"
            onClick={() => handleNavigate(tile)}
          >
            {userLoggedIn ? (
              gameStatusCheck(tile.title) === "New" ? (
                <div className="game-status new-game">New</div>
              ) : (
                <div className="game-status completed-game">completed </div>
              )
            ) : null}

            <img src={tile.img} alt="" />
            <p>{tile.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
