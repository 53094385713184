// App.js
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "../utils/validationSchema";
import axios from "axios";
import { serverPath } from "../config/keys";
import { toast } from "react-toastify";
import DatePicker from "react-date-picker";
import countryList from "../utils/countries";
import Loader from "../components/loader/loader";
import { EyeIcon, EyesShut } from "./login";
function Register({ setShowRegister }) {

  const countries = countryList();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(RegisterSchema) });
  const registerUser = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(`${serverPath}/auth/register`, values);
      const {
        status,
        data: { message },
      } = response;
      if (status === 200) {
        setShowRegister(false);
        toast.success(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(
          error?.response?.data?.message || "Internal server error!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          }
        );
        setLoading(false);
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-container">
        <div class="login-cover">
          <img src="/Images/cat.png" className="cat-img" alt="" />
        </div>

        <div className="login-form">
          <div className="login-popup-content">
            <h2>Sign Up</h2>

            <Form onSubmit={handleSubmit(registerUser)}>
              <Form.Group className="half-w">
                <Form.Control
                  className={`${errors?.firstName ? `error-border` : ``}`}
                  type="text"
                  placeholder="First name"
                  name="firstName"
                  {...register("firstName")}
                />
                {errors?.firstName ? (
                  <span className="error-msg">{errors.firstName.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="half-w">
                <Form.Control
                  className={`${errors?.name ? `error-border` : ``}`}
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  {...register("lastName")}
                />
                {errors?.lastName ? (
                  <span className="error-msg">{errors.lastName.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="full-w">
                <Form.Control
                  className={`${errors?.userName ? `error-border` : ``}`}
                  type="text"
                  placeholder="Username"
                  name="userName"
                  {...register("userName")}
                />
                {errors?.userName ? (
                  <span className="error-msg">{errors.userName.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className="full-w">
                <Form.Control
                  className={`${errors?.email ? `error-border` : ``}`}
                  type="text"
                  name="email"
                  placeholder="Email address"
                  {...register("email")}
                />
                {errors?.email ? (
                  <span className="error-msg">{errors.email.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="full-w">
                <Form.Select
                  aria-label="Default"
                  onChange={(el) => setValue("country", el?.target?.value)}
                >
                  <option>Please select your country</option>
                  {countries &&
                    countries?.length > 0 &&
                    countries.map((el) => (
                      <option value={el?.label}>{el?.label}</option>
                    ))}
                </Form.Select>
                {errors?.country ? (
                  <span className="error-msg">{errors.country.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="full-w">
                <DatePicker
                  onChange={(date) => {
                    setDateOfBirth(date);
                    setValue("dateOfBirth", date);
                  }}
                  value={dateOfBirth}
                  format="dd-MM-y"
                  dayPlaceholder="DD"
                  clearIcon={
                    dateOfBirth ? <span className="mb-4">❌</span> : null
                  }
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  maxDate={new Date()}
                />
                {errors?.dateOfBirth ? (
                  <span className="error-msg">
                    {errors?.dateOfBirth?.message}
                  </span>
                ) : (
                  ""
                )}
              </Form.Group>
              <Form.Group className="full-w">
                <div className="pass-show password-input">
                  <Form.Control
                    className={`${errors?.password ? `error-border` : ``}`}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    {...register("password")}
                  />

                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="eye-icon"
                  >
                    {showPassword ? <EyesShut /> : <EyeIcon />}
                  </span>
                </div>

                {errors?.password ? (
                  <span className="error-msg">{errors.password.message}</span>
                ) : (
                  ""
                )}
              </Form.Group>
              <div className="full-w">
                <Button type="submit" className="auth-btn">
                  {loading ? <Loader /> : "Sign Up"}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
