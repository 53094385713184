import { serverPath } from "./keys";
import axios from "axios";

export const authInstance = () => axios.create({
    baseURL: `${serverPath}/auth`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
});

export const gameDataInstance = () => axios.create({
    baseURL: `${serverPath}/game`,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
});