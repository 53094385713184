import React ,{useState, useEffect} from "react";
import Header from "../components/header";

function LearnNumberQuiz() {
  const [token, setToken] = useState()
  useEffect(()=>{
    const token = localStorage.getItem('token')
    setToken(token)
  },[])
  return (
    <div className="landing-wrapper">
        <div className="landing-container">
        <Header />
        <div className="game-wrapper">
            <div className="iframe-container">
                <iframe title="Game" src={`https://mandaean-number-quiz.netlify.app/?token=${token}`} ></iframe>
            </div>
        </div>
        </div>
    </div>
  );
}

export default LearnNumberQuiz;
